import React, { useRef, useState } from 'react'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Footer from 'components/Footer'
import videomp4 from 'images/Letters-Switch.mp4'
import videowebm from 'images/Letters-Switch.webm'
import creativelyPlaceholder from 'images/landing-page-placeholder.png'

import BackgroundSection from 'components/BackgroundSection'

const PortfolioPage = () => {
  const videoRef = useRef('')

  const [play, togglePlay] = useState(false)

  function playPause() {
    if (!play) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
    }
    togglePlay(!play)
  }

  function renderVideoPlaceholder() {
    if (!play) {
      return (
        <img
          src={creativelyPlaceholder}
          className="creatively-grid-video-poster"
          alt="creatively-logo"
        />
      )
    }
    return null
  }

  const { spiritune, pbjLive, moeAssist } = useStaticQuery(
    graphql`
      query {
        spiritune: file(relativePath: { eq: "spiritune-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbjLive: file(relativePath: { eq: "pbj-live-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        moeAssist: file(relativePath: { eq: "moe-assists-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  return (
    <Layout light>
      <SEO />
      <div className="container light">
        <section className="projects__section">
          <div className="projects-wrapper">
            <div className="projects-grid type1">
              <Link to="portfolio/spiritune">
                <BackgroundSection
                  image={spiritune}
                  className="projects-grid-content --spiritune"
                >
                  <h4 className="project-heading">Spiritune</h4>
                  <p className="project-description">
                    Managing emotional well-being through music
                  </p>
                </BackgroundSection>
              </Link>

              <Link
                to="portfolio/pbj-live"
                onMouseEnter={playPause}
                onMouseLeave={playPause}
              >
                <BackgroundSection
                  image={pbjLive}
                  className="projects-grid-content --project"
                >
                  <h4 className="project-heading">PBJ Live</h4>
                  <p className="project-description">
                    Your content - wherever, whenever
                  </p>
                </BackgroundSection>
              </Link>
            </div>
            <div className="projects-grid type2">
              <Link to="portfolio/creatively">
                <div
                  className="projects-grid-content --creatively"
                  onMouseEnter={playPause}
                  onMouseLeave={playPause}
                >
                  <div className="projects-grid-content-text">
                    <h4 className="project-heading">Creatively</h4>
                    <p className="project-description">
                      Connecting the creative community
                    </p>
                  </div>
                  <div className="creatively-grid-video-wrapper">
                    <video
                      muted
                      loop
                      autoPlay={play}
                      ref={videoRef}
                      poster={creativelyPlaceholder}
                      className="creatively-grid-video"
                    >
                      <source src={videomp4} type="video/mp4" />
                      <source src={videowebm} type="video/webm" />
                    </video>
                    {renderVideoPlaceholder()}
                  </div>
                </div>
              </Link>
              <Link to="portfolio/moe-assist">
                <BackgroundSection
                  image={moeAssist}
                  className="projects-grid-content --moe-assist"
                >
                  <h4 className="project-heading">Moe Assist</h4>
                  <p className="project-description">
                    A management tool for influenecers
                  </p>
                </BackgroundSection>
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  )
}

export default PortfolioPage
